.margin-top {
  margin-top: 4.6rem;
}

.banner-text {
  width: 55rem;
  margin: auto;
}

.title-heading {
  text-align: center;
  text-shadow: -1px 3px 0px rgba(0, 0, 0, 0.08);
  font-family: Futura;
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 4.063rem;
}

.fontfamily-fm-b {
  font-family: Futura Md BT, sans-serif;
}

.title-text {
  text-align: center;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
}

/* .card .row {
    width: max-content;
} */

.link-btn {
  display: flex;
  padding: 0.5rem 1.563rem;
  justify-content: center;
  align-items: center;
  gap: 0.625;
  border-radius: 0.938rem;
  border: 1px solid #193971;
  background: #193971;
  text-decoration: none;
  width: max-content;
}

.link-btn:hover {
  background-color: #ffffff;
  color: #193971;
}

.link-text {
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.62rem;
  /* 162.5% */
}

.Clients-section a {
  text-decoration: none;
}

.cardtitle {
  color: #193971;
  font-family: Futura-Bold;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.625rem;
}

.cardtext {
  color: #193971 !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.bottom {
  margin: auto;
  width: 24rem;
}

.bottom-text {
  color: #fd5c01;
  text-align: center;
  font-size: 1.37rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  /* 145.455% */
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.563rem;
  width: 400px;
}

.custom-container {
  gap: 4.063rem;
  border-radius: 1.875rem;
  border: 0.5px solid #fd5c01;
  height: 32rem;
  flex-shrink: 0;
  margin-bottom: 5rem;
  padding: 3rem 2rem;
}

.img-frame {
  position: relative;
}

.img-desktop {
  width: 34.654rem;
  height: 22.701rem;
  flex-shrink: 0;
  border-radius: 1.375rem 1.375rem 0px 0px;
  background: var(--default-shade-black, #000);
}

.img-desktop .imgdesktopview {
  width: 33.536rem;
  height: 21.111rem;
  flex-shrink: 0;
  border-radius: 0.75rem 0.75rem 0px 0px;
}

.img-tab {
  position: absolute;
  left: 4%;
  bottom: -11%;
  width: 18.188rem;
  height: 13.25rem;
  flex-shrink: 0;
  background: url(../assets/AppleiPadPro11SilverLandscape.png) no-repeat;
}

.gap2 {
  gap: 2rem;
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
  .custom-container {
    gap: 4.063rem;
    border-radius: 1.875rem;
    border: 0.5px solid #fd5c01;
    height: 42rem;
    flex-shrink: 0;
    margin-bottom: 5rem;
    padding: 3rem 2rem;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 768px) {
  .img-desktop .imgdesktopview {
    width: 22.536rem;
    height: 14.111rem;
    flex-shrink: 0;
    border-radius: 0.75rem 0.75rem 0px 0px;
  }

  .img-desktop {
    width: 24rem;
    height: 16rem;
  }

  .img-tab {
    position: absolute;
    left: 3%;
    bottom: 21%;
    height: 11rem;
    width: 15rem;
    background: url(../assets/AppleiPadPro11SilverLandscape.png) no-repeat;
    background-size: cover;
  }

  .imgtabview {
    height: 10rem;
    width: 14.1rem;
  }

  .title-text {
    text-wrap: wrap;
  }

  .banner-text {
    width: 40rem;
  }

}

@media only screen and (max-width: 768px) {
  .custom-container {
    gap: 4.063rem;
    border-radius: 1.875rem;
    border: 0.5px solid #fd5c01;
    height: 45rem;
    flex-shrink: 0;
    margin-bottom: 5rem;
    padding: 3rem 2rem;
  }

  .img-desktop {
    width: 26rem;
    height: 17rem;
    flex-shrink: 0;
    border-radius: 1.375rem 1.375rem 0px 0px;
    background: var(--default-shade-black, #000);
  }

  .img-desktop .imgdesktopview {
    width: 25rem;
    height: auto;
    flex-shrink: 0;
    border-radius: 0.75rem 0.75rem 0px 0px;
  }

  .img-tab {
    position: absolute;
    left: 4%;
    bottom: -11%;
    width: 18.188rem;
    height: 13.25rem;
    background: url(../assets/AppleiPadPro11SilverLandscape.png) no-repeat;
    background-size: cover;
  }

  .banner-text {
    width: 30rem;
  }
}

@media only screen and (max-width: 480px) {
  .banner-text {
    width: 100%;
  }

  .title-heading {
    text-align: center;
    text-shadow: -1px 3px 0px rgba(0, 0, 0, 0.08);
    font-family: Futura;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.5rem;
  }

  .custom-container {
    gap: 4.063rem;
    height: auto;
  }

  .img-desktop {
    width: 22rem;
    height: 14rem;
  }

  .img-desktop .imgdesktopview {
    width: 21rem;
    height: auto;
  }

  .img-tab {
    position: absolute;
    left: 4%;
    bottom: -11%;
    width: 18.188rem;
  }
}

@media only screen and (max-width: 400px) {
  .img-desktop {
    width: 15.5rem;
    height: 13rem;
  }

  .img-desktop .imgdesktopview {
    width: 14.5rem;
    height: 12rem;
  }

  .bottom-text {
    color: #fd5c01;
    text-align: center;
    font-size: 1.37rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    width: 20rem;
    margin: 1rem;
  }

  .title-text {
    text-align: center;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    width: 100%;
    margin: 0;
  }
}