.header {
    background: #FFFFFF;

}

.header-item {
    display: flex;
    justify-content: space-between;
    height: 70px;
    align-items: center;
    max-width: 1320px;
    margin: auto;
    padding: 0 .7rem;
}

.logo {
    width: 200px;
}

.authbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
}

.authbutton .about {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 20px;
    margin-top: .3rem;
}

.googleauth {
    cursor: pointer;
}

.authbutton .about {
    color: #193971;
    opacity: 1;
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
}

.authbutton .about:hover {
    color: #FD5C01;
    opacity: 1;
    transition: .3s;
}

.login {
    /* color: black; */
    opacity: 1;
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
    color: #EB672C;
    margin-right: 4px;
}

.login:hover {
    color: #FD5C01;
    transition: .3s;
    opacity: 1;
}

.profile {
    color: #193971;
    opacity: 1;
    cursor: pointer;
}

.profile :hover {
    color: #FD5C01;
    opacity: 1;
    transition: .3s;
}

/* Navbar.css */
.header .dropdown-container {
    position: relative;

}

.header .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    display: none;
    background: solid white;
    border-radius: 0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    padding: .5rem .5rem;
    opacity: 1;
    height: auto;
    width: max-content;
}

.header a {
    text-decoration: none;
}

.header .drop-item {
    width: 100%;
    color: #193971;
    border-radius: 2px;
    padding: .3rem .3rem;
    font-size: 1rem;
    font-weight: 600;
    flex-wrap: wrap;
    text-decoration: none;
    cursor: pointer;
}

.header .drop-item:hover {
    /* border: 2px solid #EB672C; */
    /* color: #EB672C; */
    transition: .3s;
    background-color: #FD5C01;
    color: white;

}

.dropdown-menu li:hover {
    background-color: #ccc;
}

.dropdown-container:hover .dropdown-menu {
    display: block;
}


@media screen and (max-width: 480px) {

    .logo {
        width: 130px;
    }
    .header {
        padding-right: .2rem;
        border-bottom: 0;
    }

    .profile {
        height: 20;
        ;
        width: 20;
    }

    .header .login {
        display: none;
    }

    .header .sign {
        display: none;
    }

    .authbutton .about {
        margin-right: 1rem;
    }

    .bookDemoBtn {
        display: none;
    }
}